import React, {useEffect, useRef, useState} from 'react'
import * as styles from './Select.module.css'
import {ROIProps} from './types'

const Select = ({options, handleChange}: ROIProps) => {
  const selectRef = useRef(null)
  const [selected, setSelected] = useState(options[0])
  const [show, setShow] = useState(false)

  const sorted = ['day', 'week', 'month', 'year']

  const handleToggle = () => {
    setShow(!show)
  }

  const handleOption = option => {
    setSelected(option)
    handleChange(option)
  }

  // const orderedOptions = options.sort(function (x, y) {
  //   return x === selected ? -1 : y === selected ? 1 : 0
  // })

  useEffect(() => {
    function handleClickAway(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickAway)
    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  })

  return (
    <div
      className={styles.custom_select}
      onClick={handleToggle}
      ref={selectRef}
    >
      <h3>per {selected}</h3>
      <div className={show ? styles.arrow_up : styles.arrow_down} />
      {show && (
        <div className={styles.options_container}>
          <ul>
            {sorted.map(option => (
              <li onClick={() => handleOption(option)} key={option}>
                per {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
export default Select
