import {validateEmail} from './scripts'
const HUBSPOT_API_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/5383285/09c614a3-a76a-4a75-95da-1b48bc2f4749'

async function sendEmail(email, calculator, application, input) {
  // find all the variables ot send to the form
  const hubspotForm = {
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
  }

  const formFields = {
    roi_calculator_type: calculator,
    roi_calc_application: application,
  }

  if (calculator === 'throughput') {
    formFields.throughput_robot_count = input.robots
    formFields.throughput_timescale = input.select
    formFields.throughput_hours_pipetting =
      parseInt(input.robots * input.hours) + parseInt(input.hours)
    formFields.throughput_hours_increase = input.robots + 1
    formFields.throughput_samples =
      parseInt(input.samples) + parseInt(input.robots * input.samples)
    formFields.throughput_samples_increase = input.robots + 1
  } else if (calculator === 'rsi') {
    formFields.rsi_robot_count = input.robots
    formFields.rsi_timescale = input.select
    formFields.rsi_hours_saved = input.hours * input.robots
  } else if (calculator === 'reagent_cost') {
    formFields.reagent_timescale = input.select
    if (input.select === 'day') {
      formFields.reagent_savings = (
        input.samples *
        input.rate *
        0.01 *
        input.cost
      ).toFixed(2)
    } else if (input.select === 'week') {
      formFields.reagent_savings = (
        input.samples *
        input.rate *
        0.01 *
        input.cost *
        5
      ).toFixed(2)
    } else if (input.select === 'month') {
      formFields.reagent_savings = (
        input.samples *
        input.rate *
        0.01 *
        input.cost *
        5 *
        4
      ).toFixed(2)
    } else if (input.select === 'year') {
      formFields.reagent_savings = (
        input.samples *
        input.rate *
        0.01 *
        input.cost *
        5 *
        4 *
        12
      ).toFixed(2)
    }
    formFields.reagent_breakeven_months = (
      13655 /
      (input.samples * input.rate * 0.01 * input.cost * 5 * 4)
    ).toFixed(1)
  } else if (calculator === 'operating_cost') {
    if (input.select === 'day') {
      formFields.operating_breakeven_weeks = (
        13655 /
        ((input.hours * 5 * (input.salary / 52 / 5)) / 8)
      ).toFixed(1)
      formFields.operating_year1_savings = (
        input.hours * 5 * 52 * (parseFloat(input.salary) / 52 / 5 / 8) -
        13655
      )
        .toFixed(2)
        .toLocaleString()
      formFields.operating_year2_savings = (
        input.hours *
        5 *
        52 *
        (parseFloat(input.salary) / 52 / 5 / 8)
      )
        .toFixed(2)
        .toLocaleString()
    } else if (input.select === 'week') {
      formFields.operating_breakeven_weeks = (
        13655 /
        ((input.hours * (input.salary / 52 / 5)) / 8)
      ).toFixed(1)
      formFields.operating_year1_savings = (
        input.hours * 52 * (parseFloat(input.salary) / 52 / 5 / 8) -
        13655
      )
        .toFixed(2)
        .toLocaleString()
      formFields.operating_year2_savings = (
        input.hours *
        52 *
        (parseFloat(input.salary) / 52 / 5 / 8)
      )
        .toFixed(2)
        .toLocaleString()
    } else if (input.select === 'month') {
      formFields.operating_breakeven_weeks = (
        13655 /
        (((input.hours / 4) * (input.salary / 52 / 5)) / 8)
      ).toFixed(1)
      formFields.operating_year1_savings = (
        input.hours * 12 * (parseFloat(input.salary) / 52 / 5 / 8) -
        13655
      )
        .toFixed(2)
        .toLocaleString()
      formFields.operating_year2_savings = (
        input.hours *
        12 *
        (parseFloat(input.salary) / 52 / 5 / 8)
      )
        .toFixed(2)
        .toLocaleString()
    } else if (input.select === 'year') {
      formFields.operating_breakeven_weeks = (
        13655 /
        (((input.hours / 52) * (input.salary / 52 / 5)) / 8)
      ).toFixed(1)
      formFields.operating_year1_savings = (
        input.hours * (parseFloat(input.salary) / 52 / 5 / 8) -
        13655
      )
        .toFixed(2)
        .toLocaleString()
      formFields.operating_year2_savings = (
        input.hours *
        (parseFloat(input.salary) / 52 / 5 / 8)
      )
        .toFixed(2)
        .toLocaleString()
    }
  }
  Object.keys(formFields).forEach(key =>
    hubspotForm.fields.push({
      name: key,
      value: formFields[key],
    })
  )
  if (validateEmail(email)) {
    try {
      const fetchResponse = await fetch(HUBSPOT_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(hubspotForm),
      })
      const data = await fetchResponse.json()
      if (data.status === 'error') {
        return {error: data.message}
      } else {
        return {error: null, success: true}
      }
    } catch {
      return {
        error: 'An error occured, please try again.',
      }
    }
  } else {
    return {
      error: 'Please enter a valid email address',
    }
  }
}

export {sendEmail}
