import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../../components/Page'
import Calculator from '../../../components/ROICalculator/Calculator'
import Questionaire from '../../../components/ROICalculator/Questionare'
import {ROI_CALCULATOR_DATA} from '../../../components/ROICalculator/ROI-data'
import {ROI_CALCULATOR_DATA_SPECIFIC} from '../../../components/ROICalculator/ROI-dataSpecific'

const CalculatorTemplate = props => {
  let isStartRoot = false
  if (localStorage.getItem('conv_session')) {
    const getCookie = JSON.parse(localStorage.getItem('conv_session'))
    const startURL = getCookie.startUrl
    if (
      startURL.substring(startURL.length - 4) === '/roi' ||
      startURL.substring(startURL.length - 5) === 'users' ||
      startURL.substring(startURL.length - 6) === 'users/'
    ) {
      isStartRoot = true
    }
  }
  const {calculator, application} = props.params
  let page
  if (isStartRoot) {
    page = ROI_CALCULATOR_DATA[application ? calculator : 'chooseApplication']
  } else {
    page =
      ROI_CALCULATOR_DATA_SPECIFIC[
        application ? calculator : 'chooseApplication'
      ]
  }
  const options = page?.options

  return (
    <Page>
      {application ? (
        <Calculator calculator={calculator} application={application} />
      ) : (
        <Questionaire page={page} options={options} />
      )}
    </Page>
  )
}

CalculatorTemplate.propTypes = {
  params: PropTypes.object,
}

export default CalculatorTemplate
