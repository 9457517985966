import React, {useState, useRef, useEffect} from 'react'
import reactStringReplace from 'react-string-replace'
import CurrencyInput from 'react-currency-input-field'
import {Button, InputField, Icon} from '../../ui-components'
import * as styles from './ROI.module.css'
import {ROI_CALCULATOR_DATA} from './ROI-data'
import {ROI_CALCULATOR_DATA_SPECIFIC} from './ROI-dataSpecific'
import Back from './Back'
import {sendEmail} from './emailUtils'
import {ROIProps} from './types'
import Select from './Select'

const Calculator = ({calculator, application}: ROIProps) => {
  let isStartRoot = false
  if (localStorage.getItem('conv_session')) {
    const getCookie = JSON.parse(localStorage.getItem('conv_session'))
    const startURL = getCookie.startUrl
    if (
      startURL.substring(startURL.length - 4) === '/roi' ||
      startURL.substring(startURL.length - 5) === 'users' ||
      startURL.substring(startURL.length - 6) === 'users/'
    ) {
      isStartRoot = true
    }
  }
  const resultsRef = useRef(null)
  const resultsContainerRef = useRef(null)
  const tooltipRef = useRef(null)
  const tipRef = useRef(null)
  const [width, setWidth] = useState(window.innerWidth)
  const [email, setEmail] = useState()
  const [emailSent, setEmailSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [input, setInput] = useState({
    hours: 6,
    samples: 96,
    robots: 1,
    rate: 10,
    cost: 48,
    salary: 35000,
    select: 'day',
  })
  const [error, setError] = useState({})
  const [showResults, setShowResults] = useState(false)
  const [showtooltip, setShowTooltip] = useState(false)
  const [tooltip, setTooltip] = useState()

  let page
  if (isStartRoot) {
    page = ROI_CALCULATOR_DATA[calculator]
  } else {
    page = ROI_CALCULATOR_DATA_SPECIFIC[calculator]
  }

  const options = page.options

  const handleChange = event => {
    if (typeof event === 'string') {
      setInput({...input, select: event})
      setError({})
    } else {
      if (parseFloat(event.target.value) > parseInt(event.target.max)) {
        setInput({...input, [event.target.name]: event.target.max})
        setError({
          ...error,
          [event.target
            .name]: `Please enter values at or below ${event.target.max} hours`,
        })
      } else if (
        parseFloat(event.target.value) < 1 ||
        isNaN(event.target.value)
      ) {
        setInput({...input, [event.target.name]: 1})
        setError({
          ...error,
          [event.target.name]: `Please enter a number greater than 0`,
        })
      } else {
        setInput({
          ...input,
          [event.target.name]: Math.round(event.target.value),
        })
        setError({...error, [event.target.name]: null})
      }
    }
  }

  const handleCalculate = () => {
    setShowResults(true)
    setTimeout(() => {
      if (resultsRef.current && width < 1024) {
        resultsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }, 50)
  }

  const handleRobotsChange = event => {
    if (event.target.value > 0) setInput({...input, robots: event.target.value})
    // else notify cant be zero
  }

  function Calculate(func, vars) {
    return new Function('v', 'with (v) { return (' + func + ')}')(vars)
  }

  const injectApplication = (str, match) => {
    if (application === 'other') {
      // remove the in before application too - this is a little hacky
      return str.replace('#application', '').replace(match, '')
    } else {
      return str.replace(
        '#application',
        application
          .replace(/_/g, ' ')
          .replace('pcr', 'PCR')
          .replace('ngs', 'NGS')
      )
    }
  }

  const injectSelect = (str, options) => {
    return reactStringReplace(str, '#select', () => (
      <Select options={options} handleChange={handleChange} />
    ))
  }

  const handleShowTooltip = tip => {
    setTooltip(tip)
    setShowTooltip(!showtooltip)
  }

  const handleSubmit = () => {
    setSending(true)
    sendEmail(email, calculator, application, input).then(resp => {
      setSending(false)
      if (resp.error) {
        setError({...error, email: resp.error})
      } else if (resp.success) {
        setError({...error, email: null})
        setEmailSent(true)
      }
    })
  }
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (input.select === 'day' && input.hours > 24) {
      setInput({...input, hours: 24})
      setError({...error, hours: null})
    }

    if (input.select === 'week' && input.hours > 168) {
      setInput({...input, hours: 168})
      setError({...error, hours: null})
    }

    if (input.select === 'month' && input.hours > 672) {
      setInput({...input, hours: 672})
      setError({...error, hours: null})
    }

    if (input.select === 'year' && input.hours > 8760) {
      setInput({...input, hours: 8760})
      setError({...error, hours: null})
    }

    if (input.robots < 1) setInput({...input, robots: 1})

    function handleClickAway(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false)
      }
    }

    window.addEventListener('resize', handleWindowSizeChange)
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [tooltipRef, input, showtooltip])

  return (
    <div className={styles.container}>
      <div className={styles.question_container}>
        <Back />
        <div className={styles.box}>
          <h2 className={styles.h2}>
            {injectApplication(page.description, ' in ')}
          </h2>
          {options.map(item => (
            <div key={item.label} className={styles.input_container}>
              {item.type === 'money' || item.type === 'percentage' ? (
                <label>
                  <div className={styles.label}>
                    {injectSelect(item.title, item.select)}
                    <div className={styles.currency}>
                      <CurrencyInput
                        className={
                          error[item.input]
                            ? styles.error
                            : `${styles.input} ${styles.currency}`
                        }
                        name={item.input}
                        defaultValue={1}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        prefix={item.type === 'money' ? '$' : null}
                        suffix={item.type === 'percentage' ? '%' : null}
                        value={input[item.input]}
                        onValueChange={(value, name) => {
                          if (value < 1) {
                            value = 1
                            setError({
                              ...error,
                              [name]: `Please enter a number greater than 0`,
                            })
                          } else {
                            setError({...error, [name]: null})
                          }
                          return setInput({...input, [name]: value})
                        }}
                      />
                    </div>
                  </div>
                </label>
              ) : (
                <InputField
                  type="number"
                  max={item.limit ? item.limit[input.select] : undefined}
                  min={1}
                  className={error[item.input] ? styles.error : null}
                  label={injectSelect(item.title, item.select)}
                  name={item.input}
                  value={
                    input.hasOwnProperty(item.input) ? input[item.input] : 0
                  }
                  onChange={handleChange}
                  labelTextClassName={styles.label}
                />
              )}

              {error[item.input] && (
                <div className={styles.error_container}>
                  <Icon iconName="error_warning" />
                  <span className={styles.error_caption}>
                    {error[item.input]}
                  </span>
                </div>
              )}
            </div>
          ))}
          <Button
            white
            className={styles.black_button}
            onClick={handleCalculate}
          >
            calculate
          </Button>
        </div>
      </div>
      {showResults && (
        <div
          className={`${styles.results_container} ${
            width > 1024 ? styles.new_item : null
          }`}
          ref={resultsRef}
        >
          <div className={styles.box}>
            {showtooltip && (
              <div className={`${styles.tooltip} ${{top: 110}}`} ref={tipRef}>
                <span
                  style={{
                    right: Math.round(
                      (328 - resultsContainerRef.current?.offsetWidth) / 2
                    ),
                  }}
                />
                {tooltip}
                <span
                  style={{
                    right:
                      Math.round(
                        (328 - resultsContainerRef.current?.offsetWidth) / 2
                      ) + 1,
                  }}
                />
              </div>
            )}
            {page.calculator.sections.map(section => {
              const vars = {}
              section.variables.map(
                variable => (vars[variable] = input[variable])
              )
              return (
                <>
                  <div className={styles.section_title_container}>
                    <h3
                      className={
                        section.color === 'blue'
                          ? `${styles.blue} ${styles.h3}`
                          : styles.h3
                      }
                    >
                      {section.title}
                    </h3>
                    {section.tooltip && (
                      <div ref={tooltipRef}>
                        <Icon
                          onClick={() => handleShowTooltip(section.tooltip)}
                          iconName="info"
                          width={12}
                          className={styles.info}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.calculator_results_container}
                    ref={resultsContainerRef}
                  >
                    {section.showOT2 && (
                      <div className={styles.roboto_container}>
                        <div className={styles.number_select_container}>
                          <InputField
                            type="text"
                            className={styles.small_input}
                            onChange={handleRobotsChange}
                            value={input.robots}
                          />
                          <div className={styles.selector_container}>
                            <div
                              onClick={() =>
                                setInput({...input, robots: input.robots + 1})
                              }
                            >
                              <span
                                className={`${styles.arrow} ${styles.up}`}
                              />
                            </div>
                            <div
                              onClick={() =>
                                setInput({...input, robots: input.robots - 1})
                              }
                            >
                              <span
                                className={`${styles.arrow} ${styles.down}`}
                              />
                            </div>
                          </div>
                        </div>
                        <h3 className={`${styles.blue} ${styles.h3}`}>
                          OT-2 Robot
                        </h3>
                        {section.showOT2Info && (
                          <div ref={tooltipRef}>
                            <Icon
                              onClick={() =>
                                handleShowTooltip(section.showOT2Info)
                              }
                              iconName="info"
                              width={12}
                              className={styles.info}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className={styles.results}>
                      {section.results.map(result => {
                        return (
                          <div key={result.title}>
                            <h5
                              dangerouslySetInnerHTML={{
                                __html: result.title
                                  .replace(
                                    `#${result.variable}`,
                                    typeof result.calculation === 'object'
                                      ? Calculate(
                                          result.calculation[
                                            input.select ? input.select : 'day'
                                          ],
                                          vars
                                        ) === 'NaN' ||
                                        Calculate(
                                          result.calculation[
                                            input.select ? input.select : 'day'
                                          ],
                                          vars
                                        ) === 'Infinity'
                                        ? 0
                                        : Calculate(
                                            result.calculation[
                                              input.select
                                                ? input.select
                                                : 'day'
                                            ],
                                            vars
                                          )
                                      : Calculate(result.calculation, vars) ===
                                          'NaN' ||
                                        Calculate(result.calculation, vars) ===
                                          'Infinity'
                                      ? 0
                                      : Calculate(result.calculation, vars)
                                  )
                                  .replace('#select', `per ${input.select}`),
                              }}
                            />
                            <span>
                              {result.subtitle?.replace(
                                '#robots',
                                Calculate(result.calculation2, vars) ===
                                  'NaN' ||
                                  Calculate(result.calculation2, vars) ===
                                    'Infinity'
                                  ? 0
                                  : Calculate(result.calculation2, vars)
                              )}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
              )
            })}
            <h3 className={`${styles.blue} ${styles.h3}`}>
              {application === 'other'
                ? 'Learn how to automate with the OT-2'
                : injectApplication(
                    'Learn how to automate #application with the OT-2'
                  )}
            </h3>
            <>
              {emailSent ? (
                <span className={styles.feedback}>
                  Thank you. We will be in contact shortly
                </span>
              ) : (
                <>
                  <div className={styles.input_container}>
                    <InputField
                      type="text"
                      placeholder="Email address"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    {error.email && (
                      <div className={styles.error_container}>
                        <Icon iconName="error_warning" />
                        <span className={styles.error_caption}>
                          {error.email}
                        </span>
                      </div>
                    )}
                  </div>
                  <Button
                    onClick={handleSubmit}
                    white
                    className={styles.black_button}
                  >
                    {sending ? 'sending' : 'submit'}
                  </Button>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </div>
  )
}

export default Calculator
